<template>
  <div 
    :style="{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }"
  >
    <div
      :style="{
        width: '400px',
        maxWidth: '90vw'
      }"
      v-if="!!token"
    >
      <h3 class="mb-5">Reset password</h3>
      <template v-if="!sended">
        <b>Password</b>
        <v-text-field
          solo
          label="Password"
          :type="passwordVisible ? 'text' : 'password'"
          v-model="password"
          class="mt-1"
          clearable
          :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
          :loading="loading"
          @click:append="() => passwordVisible = !passwordVisible"
        ></v-text-field>
        <b>Conferma password</b>
        <v-text-field
          solo
          label="Conferma password"
          :type="passwordVisible ? 'text' : 'password'"
          v-model="passwordConfirm"
          class="mt-1"
          clearable
          :rules="[
            (value) => value == password ? true : 'Password diverse'
          ]"
          :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
          :loading="loading"
          @click:append="() => passwordVisible = !passwordVisible"
        ></v-text-field>
      </template>
      <div 
        v-else
        class="mt-6 mb-6 text-center"
      >
        Il reset della password è avvenuto correttamente.
      </div>
      <v-btn
        v-if="!sended"
        color="primary"
        block
        :loading="loading"
        @click="handleResetClick"
        :disabled="!valid"
      >Resetta</v-btn>
      <v-btn
        v-else
        color="primary"
        block
        @click="handleBackToLoginClick"
      >Torna al login</v-btn>
    </div>
    <div 
      v-else
      :style="{
        width: '400px',
        maxWidth: '90vw'
      }"
    >
      <div class="mb-6 text-center">
        Il reset della password è possibile solo tramite il link inviato per messaggio.
      </div>
      <v-btn
        color="primary"
        block
        @click="handleBackToLoginClick"
      >Torna al login</v-btn>
    </div>
  </div>
</template>

<script>
import authService from "@/services/auth/auth.service.js";

export default {
  name: "SendResetPassword",
  data: function() {
    return {
      password: undefined,
      passwordConfirm: undefined,
      passwordVisible: false,
      loading: false,
      sended: false,
    }
  },
  props: {
    token: {
      type: String,
      default: undefined
    }
  },
  methods: {
    handleResetClick() {
      this.loading = true

      authService.resetPassword(this.token, this.password)
        .then(() => {
          this.sended = true
        })
        .catch(() => {
          this.$delegates.snackbar('Errore durante il reset della password')
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleBackToLoginClick() {
      this.$router.push({
        name: 'Login'
      })
    }
  },
  computed: {
    valid() {
      return !!this.password && !!this.passwordConfirm && this.password == this.passwordConfirm
    }
  }
}
</script>

<style>
h3 {
  font-size: 1.2em;
  letter-spacing: -1px;
  font-weight: 700;
  color: black;
  text-align: center;
}
</style>